<template>

  <session-layout>

    <template slot="session-title">
      <h3>Sign up</h3>
    </template>

    <template slot="session-body">

      <v-form v-model="valid" class="mb-4">
        <v-text-field
                label="First Name"
                v-model="first_name"
                required
                :rules="[rules.required]"
                validate-on-blur>
        </v-text-field>
        <v-text-field
                label="Last Name"
                v-model="last_name"
                required
                :rules="[rules.required]"
                validate-on-blur>
        </v-text-field>
        <v-text-field
                label="Email"
                v-model="email"
                required
                :rules="[rules.required, rules.email]"
                validate-on-blur>
        </v-text-field>
        <v-text-field
                label="Password"
                v-model="password"
                :append-icon="showPassword ? 'visibility' : 'visibility_off'"
                :rules="[rules.required, rules.minLength]"
                :type="showPassword ? 'text' : 'password'"
                name="input-10-1"
                hint="At least 8 characters"
                counter
                @click:append="showPassword = !showPassword"
                validate-on-blur
                v-on:keyup.enter="submit">
        </v-text-field>
        <!--<v-select
                v-model="role"
                :rules="[rules.required]"
                :items="['Admin', 'User']"
                label="Role"
        ></v-select>
        <v-text-field
                v-mask="phoneNumberMask"
                v-model="phone_number"
                label="Phone Number"
                :rules="[rules.emptyPhone]"
        ></v-text-field>-->
      </v-form>

      <v-btn
              :disabled="signUpDisabled"
              depressed
              class="primary mt-10"
              style="width: 100%"

              @click="submit">
        Sign Up
        <v-progress-circular
                v-if="signUpInProgress"
                style="position: absolute; right: 30%;"
                indeterminate
                :size="22"
                color="#fff"
        ></v-progress-circular>
      </v-btn>
      <div class="mt-5 text-center">
        <span class="fs-13 dark-grey-text ">Already have an account?</span> <a class="text-right remember fs-13 " @click="$router.push('/session/login')">Go to login page</a>
      </div>

    </template>

  </session-layout>

</template>

<script>
  import SessionLayout from './Layout'
  const validators = {
    alphabet: /^[A-Za-z\s]+$/,
    email: /\S+@\S+\.\S+/,
    number: /^[\d]+$/,
    phone: /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/im
  }
  export default {
    name: 'SignUp',
    computed: {
      signUpDisabled: function() {
        let invalidName = !isNaN(this.first_name) || this.first_name == '' || !isNaN(this.last_name) || this.last_name == ''
        return this.signUpInProgress || this.email == '' || this.password == '' || invalidName
      }
    },
    data() {
      return {
        phoneNumberMask: '(###) ###-####',
        valid: false,
        first_name: '',
        last_name: '',
        email: '',
        password: '',
        role: "User",
        phone_number: '',
        showPassword: false,
        signUpInProgress: false,
        checkError: false,
        rules: {
          required: value => !!value || 'Required.',
          onlyAlphabet: value => validators.alphabet.test(value) || 'Invalid value.',
          integer: value => validators.integer.test(value) || 'Invalid value.',
          float: value => validators.float.test(value) || 'Invalid value',
          minLength: value => value.length >= 8 || 'Min 8 characters',
          email: value => /.+@.+\..+/.test(value) || 'E-mail must be valid',
          emptyPhone: value => {  if (value && value.length > 0) {  return validators.phone.test(value) ||  "Phone must be valid"; } else { return true }}
        },
      }
    },
    methods: {
      async submit(){
        this.signUpInProgress = true
        let checkError = this.checkError

        const user = {
          first_name: this.first_name,
          last_name: this.last_name,
          username: this.email,
          password: this.password,
          role: this.role,
          phone_number: this.phone_number,
          redirect: '/elections',
          refresh: true
        }
        const _self = this
        await this.$store.dispatch('signUpUserApi', user).then(function(response){
          const userData = response['data']
          if (userData['error']) {
            checkError = true
            _self.$store.commit('apiError', userData['error'])
            _self.signUpInProgress = false
          } else {
            _self.$router.push('/session/registered')
            _self.signUpInProgress = false
          }
        })
      }
    },
    components: {
      SessionLayout
    }
  }
</script>